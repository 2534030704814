import { yieldMainThread } from "@devowl-wp/react-utils";
import { createTcfModelFromCookie } from "./createModelFromCookie.js";
import { transformTcfAdTags } from "./transformAdTags.js";
import { BANNER_PRE_DECISION_SHOW_EVENT } from "../events/bannerPredecisionShow.js";
import { OPT_IN_ALL_EVENT } from "../events/optInAll.js";
/**
 * Dispatch events to the CMP API so vendors can listen to it.
 *
 * @see https://www.npmjs.com/package/@iabtechlabtcf/cmpapi#user-content-trigger-change-event
 */ function tcfCmpApiDispatcher(tcf, tcfMetadata, api, tcfCookieName) {
    const fnUpdateFromCurrentCookie = async (uiVisible)=>{
        const current = createTcfModelFromCookie(tcf, tcfMetadata, tcfCookieName);
        if (current) {
            // Creating the GVL object can be expensive, so generating the TC string for vendors should be generated in an own task
            await yieldMainThread();
        }
        const tcfStringForVendors = current == null ? void 0 : current.tcfStringForVendors();
        if (uiVisible) {
            // If the UI is visible, we can have an encoded TCF string
            api.update(tcfStringForVendors || "", true);
        } else if (!uiVisible) {
            if (tcfStringForVendors) {
                api.update(tcfStringForVendors, false);
            } else {
                // If the UI is not visible, and we do not have a TCF string, the GDPR does not apply for this request
                api.update(null);
            }
        }
        transformTcfAdTags(tcfStringForVendors, current == null ? void 0 : current.gvl);
    };
    // The UI got shown to the user
    document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, ()=>{
        fnUpdateFromCurrentCookie(true);
    });
    // The user has changed its privacy preferences
    document.addEventListener(OPT_IN_ALL_EVENT, ()=>{
        fnUpdateFromCurrentCookie(false);
    });
}
export { tcfCmpApiDispatcher };
