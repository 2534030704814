import { TCString } from "@iabtechlabtcf/core";
import Cookie from "js-cookie";
import { createTcfModel } from "./createModel.js";
/**
 * Get the current TCF string stored in our first party cookie and return a valid GVL instance and TCModel.
 * It returns `undefined` if no TCF string is currently present.
 */ function createTcfModelFromCookie(tcf, tcfMetadata, cookieName) {
    let cookieValue = Cookie.get(cookieName);
    // Got it saved locally?
    const localConsent = localStorage.getItem(cookieName);
    if (localConsent) {
        cookieValue = localConsent;
    }
    if (!cookieValue) {
        return undefined;
    }
    const { gvl, model } = createTcfModel({
        tcf,
        tcfMetadata,
        tcfString: cookieValue
    });
    return {
        gvl,
        model,
        tcfString: cookieValue,
        tcfStringForVendors: ()=>TCString.encode(model, {
                isForVendors: true
            })
    };
}
export { createTcfModelFromCookie };
